import { useContext, useEffect } from 'react';
import './App.css';
import GMM from './GMM';
import Main from './Main';
import { PageContext, PageProvider } from './PageProvider';
import PrivacyPolicy from './PrivacyPolicy';
import TopBar from './Layout/TopBar';
import Backdrops from './Media/Backdrops/backdrops.json'
import { useSearchParams, BrowserRouter as Router} from 'react-router-dom';

function App() {

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const getARandomBackdrop = (mode = "both") => {
    const filteredBackdrops = Backdrops.filter((backdrop) => 
      backdrop.mode === mode || backdrop.mode === "both"
    );
    if (filteredBackdrops.length === 0) {
      console.warn(`No backdrops available for mode: ${mode}`);
      return null;
    }
    const randomIndex = randomNumberInRange(0, filteredBackdrops.length - 1);
    return filteredBackdrops[randomIndex].file;
  };

  const randomBackdrop = getARandomBackdrop("dark");
  const [searchParams, setSearchParams] = useSearchParams();

  // Retrieve "currentPage" from search params or set to default "1"
  const currentPage = searchParams.get('currentPage') || 'mainPage';

  // Assume PageContext is your context where you want to set currentPage
  const { setCurrentPage } = useContext(PageContext);

  // Update the context when the search parameter changes
  useEffect(() => {
    setCurrentPage(currentPage);
  }, [currentPage, setCurrentPage]);
  
  return (

        <div className="App">
          <div className='appBg'>
            <img src={require(`./Media/Backdrops/${randomBackdrop}`)} className='backdrop' alt="Backdrop" />
            <div className='bgTint'></div>
          </div>
          <div className='contents'>
            <TopBar />
            <MainContent />
          </div>
        </div>

  );
}

const MainContent = () => {
  const { currentPage } = useContext(PageContext); // Access context here

  return (
    <div className='contentView'>
      {
        (currentPage === "mainPage" && <Main />) ||
        (currentPage === "privacyPolicy" && <PrivacyPolicy />) ||
        (currentPage === "gmm" && <GMM />) ||
        (currentPage === "gmm2" && <GMM />)
      }
    </div>
  );
}

const AppWrapper = () => {
  return (
    <Router> {/* Ensure Router is here */}
      <PageProvider> {/* Wrap with context provider */}
        <App /> {/* App contains your components and logic */}
      </PageProvider>
    </Router>
  );
}

export default AppWrapper;
