function PrivacyPolicy() {
    return (
        <>
            <ul className="privacyPolicyList">
                <li>
                    Your data: 
                    <ul>
                        <li>
                            What parts of your data are stored, where and why:
                            <ul>
                                <li>
                                    Your session creation history (all your created sessions): Stored locally on the users hard drive,<br/>can be removed by delating the roomHistory.xml file placed<br/>in "%APPDATA%\..\LocalLow\PrismGames\Granny Multiplayer\Data\". Why do we store that:<br/>We store your session history so you can copy previous session settings and<br/>create an session with same settings as the last one.
                                </li><br/>
                                <li>
                                    Your game settings: Stored locally on the users hard drive,<br/>can be removed by delating the settings.xml file placed<br/>in "%APPDATA%\..\LocalLow\PrismGames\Granny Multiplayer\Data\". Why do we store that:<br/>We store the settings so you don't need to always change the settings before jumping into the game.
                                </li>
                            </ul>
                        </li><br/>
                        <li>
                            You can access your data at all times in the Session creation screen and settings menu
                        </li>
                    </ul>
                </li><br/>
                <li>
                    How to remove your data: 
                    <ul>
                        <li>
                            Email the help email (prismgameshelp@gmail.com)
                        </li><br/>
                        <li>
                            DM @netherite_official or @super_rafyyt on Discord
                        </li>
                    </ul>
                    IMPORTANT NOTE: When removing your account data, remember to give your Account ID or in-game GUID
                </li><br/>
                <li>
                    Security:
                    <ul>
                        <li>
                        Our games will NEVER ask you for: your address, your first and last name and other private information
                        </li><br/>
                        <li>
                        Our games will NEVER send you an unknown login requests<br/>(If you recieve a login request other than logging into the Epic Games Services then you should reinstall the game)
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
}

export default PrivacyPolicy;